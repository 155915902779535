import React from 'react';
import Table from 'react-bootstrap/Table';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image';
import Spinner from 'react-bootstrap/Spinner';

import Form from 'react-bootstrap/Form';
import chatIcon from '../assets/icons/chatIcon.svg';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

import api from '../utils/api';
import Button from 'react-bootstrap/Button';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import { useState, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import WideBtn from '../components/WideBtn';
import { toast } from 'react-toastify';
import cookies from "../utils/cookies";
import { is } from 'date-fns/locale';
export default function ForumView() {
  const isMobileApp = localStorage.getItem('platform').split(',')[0];

  const [isLoading, setIsLoading] = useState(false);
  const handleHome = (e) => {
    e.preventDefault();
    console.log('DONE, redirect back to home /');
    window.location.href = "/";
  };
  const savedRole = cookies.getUserRole();
  const [data, setData] = useState([]);

  const [topics, setTopics] = useState([]);

  const [categories, setCategories] = useState([]);
  const [hasMore, setHasMore] = useState(true);

  const [selectedCategory, setSelectedCategory] = useState(null);
  const [lastSelectedCategory, setLastSelectedCategory] = useState(null);
  const fetchData = () => {
    console.log(selectedCategory);
    if (!selectedCategory) {
      return;
    }
  };

  const [pageNo, setPageNo] = useState(0);

  const [topicPageNo, setTopicPageNo] = useState(0);
  const [pageSize, setPageSize] = useState(1000);

  const truncateHTML = (html, maxLength) => {
    try {
      if (html.length > maxLength) {
        return '<span>' + html.slice(0, maxLength) + '...' + '</span>';
      }
      return '<span>' + html + '</span>';
    } catch (error) {
      console.log('error', error);
    }

  };
  const changeCategory = (category) => () => {
    console.log('changeCategory', category);
    if (category?.id !== selectedCategory?.id) {

      setSelectedCategory(category);
      setData([]);

      setTopicPageNo(0);
      setTimeout(() => {
        if (category?.id && category?.id !== undefined && category?.id !== "undefined") {

          fetchTopic();
          try {
            toast.info(category?.title + " is selected!", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
            setTimeout(() => {
              window.scrollTo(0, document.getElementById('topicSection').offsetTop);
            }, 1000);
          } catch (error) {

          }

        }

      }, 1000);
    }
  };
  const showToast = async (msg) => {
    try {
      toast.info(msg, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } catch (error) {
      console.log('error', error);
    }

  }
  const fetchCategory = (page) => {
    if (page < 0) {
      showToast('No more categories, reached end of list!');
      return;
    }

    setPageNo(page);
    let data = {
      "pageNo": page,
      "pageSize": pageSize,

      "enabled": "true"
    };

    api.fetchCategory(data).then(resp => resp?.data)
      .then(res1 => {
        console.log('fetchCategory', res1);
        setCategories(res1?.content)
      }).catch(err => {
        console.log('submit contact error', err);
      });

  }
  const fetchTopic = () => {

    if (!selectedCategory?.id || selectedCategory?.id === undefined || selectedCategory?.id === "undefined") {
      return;
    }

    let data = {
      "pageNo": topicPageNo,
      "pageSize": 5,
      "id": selectedCategory?.id,
      "enabled": "true"
    };

    api.fetchTopic(data).then(resp => resp?.data)
      .then(res1 => {
        console.log('fetchTopic', res1);
        setTimeout(() => {
          if (res1.content.length > 0) {
            // Append new data to the existing data
            // setTopics(res1?.content);

            setData(prevData => [...prevData, ...res1.content]);
            setTopicPageNo(topicPageNo + 1);
            setHasMore(true);

          } else {
            setHasMore(false);
            // showToast('No topics, in this category!');
          }
        }).catch(err => {
          // showToast(err?.response?.data?.message || 'No plan details to display');

          console.log('submit fetchTopic error', err);
        });

      });
  };
  useEffect(() => {
    fetchCategory(pageNo);
  }, []);

  const handleLoadMore = () => {
    // Fetch more data when the user scrolls to the bottom

    if (data.length >= 50) {
      setHasMore(false);
    } else {
      setHasMore(true);
      fetchTopic();
    }
  };
  const handleCreate = () => {
    //refirect to client forum
    window.location.href = "/client/create-article";

  };

  const handleUpdate = () => {
    //refirect to client forum
    window.location.href = "/client/update-article";

  };
  const handleManageNotification = () => {
    //refirect to client forum
    window.location.href = "/client/manage-notification";

  };




  const [selectedFileTopicId, setSelectedFileTopicId] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewURL, setPreviewURL] = useState('');

  const handleFileChange = (event, id) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    console.log('file', file);
    console.log(id);
    setSelectedFileTopicId(id);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewURL(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setPreviewURL('');
    }
  };

  const handleSubmit = (event) => {
    console.log('handleSubmit', event?.target);

    event.preventDefault();
    // Here you can implement your upload logic
    // For example, using FormData and sending it to your server
    if (selectedFile && isLoading === false) {

      setIsLoading(true);
      var randomId = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
      const formData = new FormData();
      console.log('formData', formData);
      //get first element in the form
      let topicId = event.target.elements[1].value;
      console.log('topicId', topicId);
      formData.append('file', selectedFile);
      formData.append('topicId', topicId);
      formData.append('imageId', randomId);
      // Example: Send formData to your backend API
      api.uploadTopicImage(formData, topicId, randomId).then(resp => resp?.data)
        .then(res1 => {
          console.log('uploadImageRes', res1);
          showToast('Image uploaded successfully!');
          //reset the form

          setSelectedFile(null);
          setSelectedFileTopicId(null);
          setPreviewURL('');

          // window.location.reload();
          setIsLoading(false);
        }).catch(err => {
          setIsLoading(false);
          console.log('submit contact error', err);
        });
    }
  };

  const uploadImage = (id) => {
    // let data = {
    //   "pageNo": page,
    //   "pageSize": pageSize,
    // };

    // api.fetchCategory(data).then(resp => resp?.data)
    //   .then(res1 => {
    //     console.log('fetchCategory', res1);
    //     setCategories(res1?.content)
    //   }).catch(err => {
    //     console.log('submit contact error', err);
    //   });
  }

  return <>

    {savedRole === 'ROLE_SUPER_ADMIN' && (<Container id="contactForm" >




      {/* <WideBtn style={{ marginBottom: 0 }} onClick={handleHome} id="finalGoToHome">
            Go back home
          </WideBtn> */}

      <ButtonGroup>
        {/*      
  <DropdownButton  variant='light' as={ButtonGroup} title="🟢 Community resources" id="bg-nested-dropdown">
    <Dropdown.Item eventKey="1">🟢 Community resources</Dropdown.Item>
  </DropdownButton>
  &nbsp;&nbsp;
  <DropdownButton  variant='light' as={ButtonGroup} title="all" id="bg-nested-dropdown">
    <Dropdown.Item eventKey="1">all</Dropdown.Item>
  </DropdownButton>
  &nbsp;&nbsp;
  <Button variant='success' style={{borderRadius: '0px', background: '#039855'}}>Latest</Button>
  &nbsp;&nbsp;  */}

        <Button variant="secondary" onClick={() => {

          window.location.href = "/";


        }}>
          Go back to home
        </Button>

        &nbsp;&nbsp;
        <Button variant='success' onClick={handleCreate} style={{ borderRadius: '0px', background: '#039855' }}>Create Category & Article</Button>
        &nbsp;&nbsp;

        <Button variant='success' onClick={handleUpdate} style={{ borderRadius: '0px', background: '#065535' }}>Update Category & Article</Button>
        &nbsp;&nbsp;

        {localStorage.getItem('pushDeviceToken') && <Button variant='success' onClick={handleManageNotification} style={{ borderRadius: '0px', background: '#212529' }}>Manage Notification</Button>


        }

      </ButtonGroup>


    </Container>)}






    <Container id="contactForm" className='text-center'>

      <Row>
        <h1>Categories List</h1>
        <hr></hr>

        {categories.length === 0 && <h3>No categories to display!</h3>}
        {categories.map(cat => (
          <Col sm={6} key={cat.id} onClick={changeCategory(cat)} style={{ cursor: 'pointer', padding: "1%" }}>
            <Card style={{
              border: selectedCategory?.id === cat?.id ? '2px solid #039855' : '2px solid transparent',
              backgroundColor: selectedCategory?.id === cat?.id ? 'white' : 'white'
            }}>
              <Card.Body>
                <section style={{ borderLeft: '5px solid #039855', paddingLeft: '5px' }}>

                  <Card.Title>{cat.title ? cat.title : <p>&nbsp;</p>}</Card.Title>
                  <Card.Subtitle className="mb-2">{cat?.categoryTitle ? cat.categoryTitle : <p>-</p>}</Card.Subtitle>
                  <Card.Text>
                    {cat.description.slice(0, 77) + (cat.description.length > 80 ? "..." : "")}
                  </Card.Text>
                  <br></br>
                  <Card.Subtitle className="mb-2 text-muted">{cat.tags.toString() ? cat.tags.toString() : <p>&nbsp;</p>}</Card.Subtitle>

                  {/* <hr></hr>
                  <p> 
                  
                  {new Date(cat?.createdDate?.seconds * 1000 + cat?.createdDate?.nanos / 1000000).toLocaleString()}
                  </p> */}


                  {/* {selectedCategory?.id === cat?.id ? (<h3>SELECTED</h3>) : (<h3></h3>)} */}

                </section>
              </Card.Body>
            </Card>
          </Col>
        ))}




      </Row>
      {/* <hr></hr>
      <Row>
        <Col>
          <Button variant='success' onClick={() => fetchCategory(pageNo - 1)} style={{ borderRadius: '0px', background: '#039855' }}>Previous</Button>
        </Col>
        <Col>

          &nbsp;&nbsp;&nbsp;&nbsp;
        </Col>
        <Col>
          <Button variant='success' onClick={() => fetchCategory(pageNo + 1)} style={{ borderRadius: '0px', background: '#039855' }}>Next</Button>
        </Col>
      </Row> */}
      {/* <pre>{JSON.stringify(topics, null, 2)}</pre> */}

    </Container>
    <br></br>
    {selectedCategory &&
      <>

        <Container id="topicSection" >
          <h1>Articles List</h1>
          <hr></hr>
          <InfiniteScroll
            dataLength={data.length}
            next={handleLoadMore}
            hasMore={hasMore}
            loader={<><h3 ><Spinner animation="border" size="sm" variant="success" />  Loading articles...</h3></>}
            endMessage={<></>}
          >
            <Table  >
              <thead>
                <tr>

                  <th>Tiltle</th>
                  <th>Author</th>
                  <th>Replies</th>
                  <th>Likes</th>
                  {savedRole === 'ROLE_SUPER_ADMIN' && (<th>Upload Image</th>)}
                </tr>
              </thead>
              <tbody>
                {data.length === 0 && <h3>No topics in this category!</h3>}
                {data.map(item => (
                  <tr key={item.id} style={{ width: '100%' }}>
                    <th style={{ width: '70%' }} >
                      <Card style={{ width: '100%', background: 'transparent', border: 'transparent' }}>
                        <Card.Body style={{ background: 'transparent', border: 'transparent' }}>
                          <Card.Title>{item.title}</Card.Title>
                          {/* <Card.Subtitle className="mb-2 text-muted">{item.description}</Card.Subtitle> */}
                          <span dangerouslySetInnerHTML={{ __html: (item.description) }} />

                          <Card.Text>{item.tags.toString()}</Card.Text>
                          <Card.Link href={'/client/forum-details/' + item?.id}>read more</Card.Link>
                        </Card.Body>
                      </Card>
                    </th>
                    <th style={{ width: '100%' }} >
                      <Card style={{ width: '100%', background: 'transparent', border: 'transparent' }}>
                        {item?.authorName && (<Card.Body style={{ background: 'transparent', border: 'transparent' }}>
                          {item?.authorName ?  cookies.getUserDisplayNameFirstS(item?.authorName ): ''}
                        </Card.Body>)}
                      </Card>
                    </th>
                    <th style={{ width: '100%' }} >
                      <Card style={{ width: '100%', background: 'transparent', border: 'transparent' }}>
                        <Card.Body style={{ background: 'transparent', border: 'transparent' }}>
                          {item?.commentCount ? item?.commentCount : 0}
                        </Card.Body>
                      </Card>
                    </th>
                    <th style={{ width: '100%' }} >
                      <Card style={{ width: '100%', background: 'transparent', border: 'transparent' }}>
                        <Card.Body style={{ background: 'transparent', border: 'transparent' }}>
                          {item.likeCount ? item?.likeCount : 0}
                        </Card.Body>
                      </Card>
                    </th>

                    {savedRole === 'ROLE_SUPER_ADMIN' && (<th style={{ width: '100%' }} >
                      <Card style={{ width: '100%', background: 'transparent', border: 'transparent' }}>
                        <Card.Body style={{ background: 'transparent', border: 'transparent' }}>
                          <Form onSubmit={handleSubmit}>
                            <input type="file" className="form-control" style={{ height: '32px', width: '100px' }} onChange={(event) => handleFileChange(event, item.id)} accept="image/*" />
                            <input type="hidden" value={item.id} />
                            {(previewURL && selectedFileTopicId === item.id) && <img src={previewURL} alt="Preview" style={{ maxWidth: '100%' }} />}
                            {/* <button type="submit" disabled={!selectedFile && selectedFileTopicId !== item.id}>Upload</button>
                         */}

                            <Button type="submit" disabled={!selectedFile || selectedFileTopicId !== item.id}
                              style={{ marginLeft: '16px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                            >
                              {isLoading && selectedFileTopicId === item.id ? <Spinner animation="border" role="status" size="sm" /> : 'Upload'}
                            </Button>
                          </Form>
                        </Card.Body>
                      </Card>
                    </th>)}
                  </tr>
                ))}
              </tbody>
            </Table>
          </InfiniteScroll>
        </Container>
      </>
    }
    <br></br>

  </>;
}